.instagram-edit-form {
    margin-top: 20px;
    width: 100%;
}

.instagram-edit-form-button {
    width: 60%;
    margin: auto;
}

.instagram-edit {
    overflow: hidden;
    width: 50%;
    border: 1px solid #EDEDF3;
    border-radius: 10px;
    margin: 10px;
}

.instagram-edit__container-error {
    text-align: center;
    border: 1px solid #ff223342;
    width: 70%;
    margin: auto;
    padding: 10px;
    color: #ff2a5e;
}

.instagram-edit__container {
    padding: 20px 0;
    background-color: #fff;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}

.instagram-edit__container > button {
    width: 30%;
    margin: 30px auto;
}

.instagram-edit__body {
    display: flex;
    justify-content: space-between;
}

.instagram-edit-head {
    border-bottom: 1px solid #ededf3;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instagram-edit-head__img {
    object-fit: cover;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    margin-bottom: 19px;
}

.instagram-edit-head__profile {
    margin-bottom: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: center;

    color: #332256;
}

.instagram-edit-head__category {
    margin-bottom: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: center;

    color: #9599b5;
}

.instagram-edit-head__stars {
    align-self: center;
    margin-left: auto;
}

.instagram-edit-head__links {
    width: 100%;
    display: flex;
}

.instagram-edit-head__links-icon {
    margin-right: 14px;
    cursor: pointer;
}

.instagram-edit-main {
}

.instagram-edit-main__characteristic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 0 20px;
    border-bottom: 1px solid #ededf3;

}

.instagram-edit-main__characteristic-item {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.instagram-edit-main__characteristic-item .MuiOutlinedInput-input {
    padding: 10px;
}

.instagram-edit-main__characteristic:last-child {
    border-bottom: none;

}

.instagram-edit-main__characteristic-value_input {
    border: none;
    text-align: right;
    outline: none;
    height: 30px;
    /*width: 160px;*/
    background: #f2f2f2;
    padding: 0 5px;
}

.instagram-edit-main__characteristic-desc {
    width: 40%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #9599b5;

}

.instagram-edit-main__characteristic-value {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;

    color: #332256;
}

.instagram-edit-main__characteristic-value-cust {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    text-align: right;

    color: #ff2a5e;
}

.instagram-edit-price {
    display: flex;
    justify-content: space-between;
    padding: 23px 30px;
}

.instagram-edit-price__desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #9599b5;
}

.instagram-edit-price__value {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: right;
    color: #ff2a5e;
}

.instagram-edit__right {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.instagram-edit__right__item {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid #EDEDF3;

    padding: 10px;
}

.instagram-edit__right__item-title {
    width: 100%;
    font-size: 17px;
    line-height: 20px;
    margin: 5px 0;
    color: #332256;
    font-style: normal;
    font-weight: 500;
}

.instagram-edit__right__item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.instagram-edit-no-border {
    border: none !important;
}

.instagram-edit__right__item-sub ~ input {
    width: 50px;
    border: 1px solid #f2f2f2;
}

.instagram-edit__right__item-sub {
    width: 100px;

    margin: 5px 0;
    font-size: 17px;
    color: #332256;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #f2f2f2;
}

.instagram-edit-conteiner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.instagram-edit-conteiner-button {
    border: 1px solid #ff502c50;
    color: #ff502c;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
}

.instagram-edit__right__table, table, td, th {
    border: none;
    text-align: start;

}

.instagram-edit__right__table, table, td, th ~ input {
}

.instagram-edit-input-age {
    border: 1px solid #9599b5;
    border-radius: 4px;
}

.instagram-edit__right__table {
    width: 90%;
    border: 1px solid #EDEDF3;
    border-radius: 10px;
    padding: 10px;
}

.instagram-edit__save {
    width: 20%;
    margin: 30px auto;
    cursor: pointer;
    background: linear-gradient(
            90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    border: none;
    text-transform: uppercase;
    padding: 10px;
    border-radius: 5px;
}

.instagram-edit__save-disable {
    background: #9599b5;
}

.instagram-edit__save:hover {
    opacity: 0.5;
}

.instagram-edit-errors-container {
    width: 100%;
}

.instagram-edit-main__characteristic > .MuiTextField-root > div > input {
    height: 3px !important;
}

.instagram-edit-errors {
    text-align: center;
    margin: 10px auto;
    color: #f23;
}