.loading {
  font-size: 80px;
  font-weight: 800;
  text-align: center;
  background: #f0f2f8;
  width: calc(100% - 250px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  background-color: #e5e5e5;
}

.loading-item {
  position: relative;
  color: #ff2a5e;
  opacity: 0.5;
  animation: loading-bloggerly-parent 4s infinite;
}

.loading-item:nth-child(1) {
  animation-delay: 0.2;
}

.loading-item:nth-child(2) {
  animation-delay: 0.4s;
}

.loading-item:nth-child(3) {
  animation-delay: 0.6s;
}

.loading-item:nth-child(4) {
  animation-delay: 0.8s;
}

.loading-item:nth-child(5) {
  animation-delay: 1s;
}

.loading-item:nth-child(6) {
  animation-delay: 1.2s;
}

.loading-item:nth-child(7) {
  animation-delay: 1.4s;
}

.loading-item:nth-child(8) {
  animation-delay: 1.6s;
}

.loading-item::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  color: #fff;
  opacity: 0;
  animation: loading-bloggerly-child 4s infinite;
}

@keyframes loading-bloggerly-parent {
  0%,
  35%,
  100% {
    color: #ff2a5e;
    opacity: 0.5;
  }
  60%,
  80% {
    color: #fff;
  }
}
@keyframes loading-bloggerly-child {
  0% {
    opacity: 1;
  }
  25%,
  100% {
    opacity: 0;
  }
}
