@font-face {
    font-family: 'Gordita';
    font-weight: 400;
    font-style: normal;
    src: local('Gordita'),
    url(../fonts/Gordita-Regular.ttf) format('truetype');
}
*{
    /*font-family: 'Gordita', serif !important;*/
}
input {
    font-family: system-ui !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}