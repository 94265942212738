.advertiser-page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 5%;
}
.advertiser-page-head{
    display: flex;
    width: 81%;
    justify-content: flex-start;
}
.advertiser-page-back{
    padding: 5px;
    font-size: 17px;
    background: none;
    border: solid 1px #3f51b5;
    color: #3f51b5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 20px 0;
}
.error_page_connect{
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #9599b5;
}
.advertiser-page-back:hover{
    opacity: 0.5;
}
.advertiser-page__container {
    /*margin: auto;*/
    background: #fff;
    padding: 50px;
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.advertiser-page__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    border-bottom: 1px solid #9599b5;
}

.advertiser-page__item-description {
    padding: 0 5px;
    margin: 5px;
    font-size: 16px;
    line-height: 20px;
    color: #9599b5;
}

.advertiser-page__item-value {
    padding: 0 5px;
    margin: 10px 5px;
}

.advertiser-page__item-button {
    padding: 5px 15px;
    margin: 10px 5px;
    background: none;
    border: solid 1px #9599b5;
    border-radius: 5px;
    cursor: pointer;
}

.advertiser-page__item-button:hover {
    opacity: 0.5;
}

.advertiser-ban {
    color: #ff502c;
    border-color: #ff502c50;
}

.advertiser-unban {
    color: rgb(0, 128, 0);
    border-color: rgb(0, 128, 0);
}