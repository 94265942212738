.notification {
  margin-right: 30px;
  display: flex;
  align-items: center;
  cursor: default;
  position: absolute;
  top: 32%;
  left: 50%;
}

.notification__img.active::before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  width: 6px;
  height: 6px;
  right: 0;
  background: #ff2a5e;
  border: 1px solid #fff;
}

.notification__dropdown {
  position: absolute;
  z-index: 10001;
  top: 31px;
  background-color: #fff;
  width: 339px;
  height: auto;
  box-shadow: 0px 4px 16px rgba(161, 165, 191, 0.3);
  border-radius: 6px;
  transform: translate(-45%, 0);
}

.notification__dropdown-item {
  width: 20px;
  height: 40px;
  position: absolute;
  align-self: center;
  cursor: pointer;
}

.notification__dropdown-item-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  padding: 20px;
}

.notification__dropdown-list {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 470px;
  overflow-y: auto;
}

.notification__dropdown-list-empty {
  display: flex;
  justify-content: center;
  margin: 12px 0 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}

.notification__dropdown-list-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e4ec;
  transition: all;
  padding: 20px;
  cursor: pointer;
  font-weight: 500;
}

.notification__dropdown-list-item img {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notification__dropdown-list-item:hover {
  background: rgba(244, 245, 249, 0.5);
}

.notification__dropdown-list-item-image {
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  background: rgba(90, 57, 247, 0.13);
  margin-right: 13px;
  position: relative;
}

.notification__dropdown-list-item-image.bell::before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 6px;
  height: 6px;
  background: #ff2a5e;
}

.notification__dropdown-list-item-information {
  display: flex;
  flex-direction: column;
}

.notification__dropdown-list-item-information-time {
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.01em;
  margin-top: 3px;
  font-weight: 400;
  color: #9599b5;
}

.notification__loader {
  border-top: 1px solid #f0f2f8;
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.notification__loader:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ff1b71;
  border-color: #ff1b71 transparent #ff1b71 transparent;
  animation: notification-loader 1.2s linear infinite;
}

@keyframes notification-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
