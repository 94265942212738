.slider-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*width: 500px;*/
    height: 500px;
    background-color: #d8d7d7;
}

.slider-img {
    width: 100%;
    max-height: 320px;

}

.slider-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 600px;
}

.slider-buttons {
    display: flex;
    width: 150px;
    justify-content: space-between;
}

.slide {
    display: none;
}

.slide.active {
    display: flex;
}

.slider-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 24px;
}

.slider-title__accaunt-name {
    text-align: center;
    padding: 10px 0;
    display: flex;
}
