.blogger-card-form{
    width: 100%;
}
.blogger-card {
    overflow: hidden;
    width: 50%;
    border: 1px solid #EDEDF3;
    border-radius: 10px;
    margin: 10px;
}
.blogger-card__container-error{
    text-align: center ;
    border: 1px solid #ff223342;
    width: 70%;
    margin: auto;
    padding: 10px;
    color: #ff2a5e;
}
.blogger-card__container {
    padding: 20px 0;
    background-color: #fff;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}

.blogger-card__body {
    display: flex;
    justify-content: space-between;
}

.blogger-card-head {
    border-bottom: 1px solid #ededf3;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blogger-card-head__img {
    object-fit: cover;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    margin-bottom: 19px;
}

.blogger-card-head__profile {
    margin-bottom: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: center;

    color: #332256;
}

.blogger-card-head__category {
    margin-bottom: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: center;

    color: #9599b5;
}

.blogger-card-head__stars {
    align-self: center;
    margin-left: auto;
}

.blogger-card-head__links {
    width: 100%;
    display: flex;
}

.blogger-card-head__links-icon {
    margin-right: 14px;
    cursor: pointer;
}

.blogger-card-main {
}

.blogger-card-main__characteristic {
    display: flex;
    justify-content: space-between;
  align-items: center;
    margin-bottom: 5px;
    padding: 0 20px;
    border-bottom: 1px solid #ededf3;

}

.blogger-card-main__characteristic:last-child {
    border-bottom: none;
}
.blogger-card-main__characteristic-value_input{
  border: none;
  text-align: right;
  outline: none;
  height: 30px;
  /*width: 160px;*/
  background: #f2f2f2;
  padding:0 5px;
}
.blogger-card-main__characteristic-desc {
    width: 40%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #9599b5;
}

.blogger-card-main__characteristic-value {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;

    color: #332256;
}

.blogger-card-main__characteristic-value-cust {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    text-align: right;

    color: #ff2a5e;
}

.blogger-card-price {
    display: flex;
    justify-content: space-between;
    padding: 23px 30px;
}

.blogger-card-price__desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #9599b5;
}

.blogger-card-price__value {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: right;
    color: #ff2a5e;
}

.blogger-card__right {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.blogger-card__right__item {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid #EDEDF3;

    padding: 10px;
}

.blogger-card__right__item-title {
    width: 100%;
    font-size: 17px;
    line-height: 20px;
    margin: 5px 0;
    color: #332256;
    font-style: normal;
    font-weight: 500;
}
.blogger-card__right__item-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.blogger-card-no-border{
    border: none!important;
}
.blogger-card__right__item-sub~input{
    width: 50px;
    border: 1px solid #f2f2f2;
}
.blogger-card__right__item-sub {
    width: 100px;

    margin: 5px 0;
    font-size: 17px;
    color: #332256;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #f2f2f2;
}
.blogger-card-conteiner{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.blogger-card-conteiner-button{
    border: 1px solid #ff502c50;
    color:#ff502c;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
}

.blogger-card__right__table, table, td, th {
    border: none;
    text-align: start;

}
.blogger-card__right__table, table, td, th ~input{
}
.blogger-card-input-age{
    border: 1px solid #9599b5;
    border-radius: 4px;
}
.blogger-card__right__table {
    width: 90%;
    border: 1px solid #EDEDF3;
    border-radius: 10px;
    padding: 10px;
}

.blogger-card__save {
    width: 20%;
    margin: 30px auto;
    cursor: pointer;
    background: linear-gradient(
            90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    border: none;
    text-transform: uppercase;
    padding: 10px;
    border-radius: 5px;
}
.blogger-card__save-disable{
background: #9599b5;
}
.blogger-card__save:hover {
    opacity: 0.5;
}
.blogger-card-errors-container{
    width: 100%;
}
.blogger-card-main__characteristic>.MuiTextField-root>div>input{
    height: 3px!important;
}
.blogger-card-errors{
    text-align: center;
    color: #f23;
}