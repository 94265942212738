.campaignsInfo-body{
 background: #fff;
    padding: 0 40px;
    box-sizing: border-box;
}
.campaignsInfo-title {
    word-break: break-all;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
}
.campaignsInfo-title-container{
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}
.campaignsInfo-title-container,input{
    border: none;
    width: 50%;
}
.campaignsInfo-container-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.campaignsInfo-description{
    width: 250px;
    color: #9599b5;
}
.campaignsInfo-container-content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
}